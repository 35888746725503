<template>
  <v-container fluid class="room-info">
    <v-row>
      <v-col cols="6">
        <div class="room-info__total">Total pièce : {{ totalPrice }}</div>
      </v-col>
      <v-col cols="6">
        <div class="room-info__square">
          <span
            >Superficie :
            {{
              !currentRoom.custom_square
                ? (currentRoom.width * currentRoom.length).toFixed(2)
                : currentRoom.custom_square.toFixed(2)
            }}m2</span
          >
          <v-btn text icon class="ml-2" @click="$emit('editRoom')">
            <IconEdit />
          </v-btn>
          <v-menu rounded="lg" offset-x>
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                color="grey"
                icon
                class="mdi-dots-button"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link @click="dialog = true">
                <v-list-item-icon>
                  <IconDelit20px style="margin-left: -3px" />
                </v-list-item-icon>
                <v-list-item-title>Supprimer la pièce</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <ConfirmDialog
      :show="dialog"
      question="Voulez-vous vraiment supprimer <br>la chambre ?"
      @closeModal="dialog = false"
      @confirmModalAgree="$emit('deleteRoom')"
    />
  </v-container>
</template>

<script>
export default {
  name: "RoomInfo",
  props: {
    currentRoom: {
      type: Object,
    },
    totalPrice: {
      type: String,
      required: true,
    },
  },
  components: {
    IconEdit: () => import("~cp/Icons/IconEdit"),
    IconDelit20px: () => import("~cp/Icons/IconDelit20px"),
    ConfirmDialog: () => import("~ui/ConfirmDialog/ConfirmDialog.vue"),
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>

<style src="./RoomInfo.scss" lang="scss" scoped></style>